.tabview-company .tabview-header-icon i,
.tabview-company .tabview-header-icon span {
  vertical-align: middle;
}

.tabview-company .tabview-header-icon span {
  margin: 0 .5rem;
}

.tabview-company .p-tabview p {
  line-height: 1.5;
  margin: 0;
}

.p-tabview-panels{
 width: 100% !important;
 height: 100% !important;
}