:root {
    @apply light;
}
.light{
    /*Colors*/
    --Primary: #003A70;
    --PCP002: #33618D;
    --PCP003: #6689A9;
    --PCP004: #99B0C6;
    --PCP005: #CCD8E2;
    --Shadow-PCP001: #003A70;
    --Shadow-PCP002: #002E5A;
    --Shadow-PCP003: #002343;
    --Shadow-PCP004: #00172D;
    --Shadow-PCP005: #000C16;
    --Secondary: #005EB8;
    --PCS002: #337EC6;
    --PCS003: #669ED4;
    --PCS004: #99BFE3;
    --PCS005: #CCDFF1;
    --Shadow-PCS001: #005EB8;
    --Shadow-PCS002: #004B93;
    --Shadow-PCS003: #00386E;
    --Shadow-PCS004: #00264A;
    --Shadow-PCS005: #001325;
    --Light: #00C0F3;
    --PCL002: #33CDFE;
    --PCL003: #66D9FE;
    --PCL004: #99E6FF;
    --PCL005: #CCF2FF;
    --Shadow-PCL001: #00C0FE;
    --Shadow-PCL002: #009ABC;
    --Shadow-PCL003: #007398;
    --Shadow-PCL004: #004D66;
    --Shadow-PCL005: #002633;
    --PrimaryText: #101010;
    --SecondaryText: #757575;
    --Divider:#BDBDBD;
    --Icon: #FFFFFF;
    --Success: #3EC161;
    --S002: #65CD81;
    --S003: #8BDAA0;
    --S004: #B2E6C0;
    --S005: #D8F3DF;
    --Shadow-S001: #3EC161;
    --Shadow-S002: #329A4E;
    --Shadow-S003: #25743A;
    --Shadow-S004: #194D27;
    --Shadow-S005: #0C2713;
    --Warning: #FFDD66;
    --W002: #FFE485;
    --W003: #FFEBA3;
    --W004: #FFF1C2;
    --W005: #FFF8E0;
    --Shadow-W001: #FFDD66;
    --Shadow-W002: #CCB152;
    --Shadow-W003: #99853D;
    --Shadow-W004: #665829;
    --Shadow-W005: #332C14;
    --Danger: #FF6666;
    --D002: #FF8585;
    --D003: #FFA3A3;
    --D004: #FFC2C2;
    --D005: #FFE0E0;
    --Shadow-D001: #FF6666;
    --Shadow-D002: #CC5252;
    --Shadow-D003: #993D3D;
    --Shadow-D004: #662929;
    --Shadow-D005: #331414;
    --Info: #BCC7FF;
    --I002: #C9D2FF;
    --I003: #D7DDFF;
    --I004: #E4E9FF;
    --I005: #F2F4FF;
    --Shadow-I001: #BCC7FF;
    --Shadow-I002: #969FCC;
    --Shadow-I003: #717799;
    --Shadow-I004: #4B5066;
    --Shadow-I005: #262833;
}

.inmplayer-trigger {
    visibility: hidden !important;
}

/* Full calendar */
#renderFullCalendar .p-datepicker table td{
    padding:  0 !important;
    width: 25px !important;
    height: 25px !important;
}
#renderFullCalendar .p-datepicker .p-datepicker-header{
    padding:  0 !important;
    height: 25px !important;
}

#renderFullCalendar .p-datepicker table td > span{
    border:  none !important;
    width: 25px !important;
    height: 25px !important;
}
#renderFullCalendar button, input, optgroup, select, textarea{
    line-height: normal !important;
}
#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-prev, #renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-next{
    visibility: hidden !important;
}
#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-prev, #renderFullCalendar  .p-datepicker .p-datepicker-header .p-datepicker-next{
    visibility: hidden !important;
}
#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-prev, #renderFullCalendar  .p-datepicker .p-datepicker-header .p-datepicker-next{
    visibility: hidden !important;
    width: 0px !important;
    height: 0px !important;
}

#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month{
    margin-right: 0px !important;
    visibility: hidden !important;
}

#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, 
#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-mont{
    padding: 0px !important;
}
#renderFullCalendar .p-datepicker-year{
    visibility: hidden !important;
}

#renderFullCalendar thead{
    font-size: 14px !important;
}

#renderFullCalendar .p-datepicker .p-datepicker-header .p-datepicker-title{
    visibility: hidden !important;
    
}



/* Group Buttons - Select Days */
#selectDaySchedule > div{
    border:1px solid #ced4da !important;
    border-right:1px solid #ced4da !important;
    padding: 0.5rem 1.6rem;
}


#selectDayWorkShifts > .p-button{
    border:1px solid  !important;
    border-radius: 50px !important;
    width: 45px !important;
    height: 45px !important;
    margin-right: 5px !important;
    text-align: center !important;
    padding: 0px !important;
}

#selectDayWorkShifts > .p-highlight {
    background: #424242 !important;
}
/*
for gradients icon gradient is from primary to secondary in linear form
for gradient linear is from primary to 
*/
@tailwind base;
@tailwind components;
@tailwind utilities;