.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  height: auto !important;
  padding-bottom: 4px !important;
  padding-left: 4px;
  padding-right: 4px;
}

/*delete scroll bar*/
.noScrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}